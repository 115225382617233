import { h } from "preact";
// import { Link } from "preact-router/match";
// import style from "./style.css";

// const Header = () => (
//   <header class={style.header}>
//     <h1>Ariel Farm</h1>
//     <nav>
//         Home
//       </Link>
//       <Link activeClassName={style.active} href="/contact">
//         Contact
//       </Link>
//       <Link activeClassName={style.active} href="/about">
//         About
//       </Link>
//     </nav>
//   </header>
// );

// export default Header;

// import { ILink } from "src/types";

const links = [
  {
    title: "Home",
    url: "/#",
  },
  // {
  //   title: "Store",
  //   url: "/store",
  // },
  // {
  //   title: "Blog",
  //   url: "/blog",
  // },

  {
    title: "About",
    url: "/about",
  },
  {
    title: "Contact",
    url: "/contact",
  },
];

const Header = () => {
  return (
    <header className="ba bg-black-90 relative top-0 left-0  ph3 pv3 pv4-ns ph4-m ph5-l z-max">
      <nav className="f6 fw6 ttu tracked ">
        <div className="db dtc-l link dim white dib mr3">
          {links.map((link, index) => (
            <a
              className="link dim white dib mr3"
              href={link.url}
              title={link.title}
              key={index}
            >
              {link.title}
            </a>
          ))}
        </div>
      </nav>
    </header>
  );
};

export default Header;
