import { Fragment, h } from "preact";
import { Route, Router } from "preact-router";

import Header from "./header";

// Code-splitting is automated for `routes` directory
import Home from "../routes/home";
import Contact from "../routes/contact";
import About from "../routes/about";
import Blog from "../routes/blog";

const App = () => (
  <Fragment>
    <head>
      <link
        rel="stylesheet"
        href="https://unpkg.com/tachyons/css/tachyons.min.css"
      />
    </head>
    <div id="app">
      <Header />
      <Router>
        <Route path="/" component={Home} />
        <Route path="/contact" component={Contact} />
        <Route path="/about" component={About} />
        <Route path="/blog" component={Blog} />
      </Router>
    </div>
  </Fragment>
);

export default App;
